<template>
  <div>
    <b-card>
      <div style="text-align; center;">
        <h3>
          Całkowity przychód: {{ data.income }} zł
        </h3>
      </div>
    </b-card>
    <b-card>
      <div style="text-align; center;">
        <h3>
          Ilość zamówień: {{ data.orders_quantity }}
        </h3>
      </div>
    </b-card>

    <b-card>
      <router-link :to="{path: '/Podsumowanie-dzienne'}">
        <button
          class="btn btn-primary"
          style="width: 100%;"
        >
          powrót
        </button>
      </router-link>
    </b-card>

  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'
import NettoChart from './NettoChart'
import ClientsChart from './ClientsChart'
import WorkersChart from './WorkersChart.vue'

export default {
  name: 'DailySummary',
  components: {
    BCard,
    BCardText,
    BLink,
    NettoChart,
    ClientsChart,
    WorkersChart,
  },
  data() {
    return {
      employees: [],
      data: {},
    }
  },
  mounted() {
    document.title = `Podsumowanie dla pracownika - ${this.$route.params.id} - Pickmode`

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}Report/${this.$route.params.id}`, config)
      .then(response => {
        this.data = response.data
        swal.fire({
          title: 'Success',
          text: 'Pomyślnie wygenerowano raport',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
      }).catch(error => {
        if (error.response.status === 404 || error.response.status === 401) {
          this.$router.push('/error-404')
        }
      })
  },
}
</script>

<style>

</style>
